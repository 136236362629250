<template>
  <div class="container">
    <div class="search">
      <div class="search-left">
        <a-input placeholder="输入病案号" type="text" v-model="patientnum" />
        <a-button type="primary" @click="search">搜索</a-button>
      </div>
      <div
        class="export"
        v-if="
          getJurisdictionList.indexOf('patientstaticinformation-downloadBtn') >
          -1
        "
      >
        <a-button type="primary" @click="derive">下载</a-button>
      </div>
    </div>

    <div
      v-if="
          getJurisdictionList.indexOf('patientstaticinformation-checkList') > -1
        "
      class="table-plate"
    >
      <ant-tabel
        v-if="show"
        :data="data"
        :columns="columns"
        :loading="loading"
        :pagination="pagination"
        @changePaging="changePaging"
      ></ant-tabel>
    </div>
  </div>
</template>
<script>
import excelUtil from "../../utils/dealwithExcelUtil";
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
import moment from "moment";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";
const columns = [
  {
    title: "ID",
    dataIndex: "num",
    key: "num",
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  {
    title: "病案号",
    dataIndex: "record_number",
    key: "record_number",
  },
  { title: "年龄", dataIndex: "age", key: "age" },
  // { title: "性别", dataIndex: "sex", key: "sex" },
  { title: "体重(KG)", dataIndex: "weight", key: "weight" },
  { title: "是否有糖尿病", dataIndex: "is_diabetes", key: "is_diabetes" },
  {
    title: "ICU主要诊断",
    dataIndex: "icu_diagnosis",
    key: "icu_diagnosis",
    scopedSlots: { customRender: "icu_diagnosis" },
  },
  { title: "入ICU时间", dataIndex: "icu_date", key: "icu_date" },
  { title: "出ICU时间", dataIndex: "out_icu_date", key: "out_icu_date" },
  {
    title: "出ICU时间来源",
    dataIndex: "out_icu_source",
    key: "out_icu_source",
  },
  {
    title: "转出ICU原由",
    dataIndex: "out_icu_reason",
    key: "out_icu_reason",
  },

  {
    title: "医院信息",
    dataIndex: "hospitalName",
    key: "hospitalName",
  },
  // {
  //   title: "操作",
  //   key: "operation",
  //   scopedSlots: { customRender: "operations" },
  // },
];

const data = [];
export default {
  components: { AntTabel },
  data() {
    return {
      data,
      show: false,
      columns,
      loading: true,
      patientnum: "",
      getJurisdictionList: "",
      sickdata: "",
      mergeData: [],
      routes: [
        {
          path: "index",
          breadcrumbName: "Patient-Staticinformation Record",
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
    };
  },
  created() {
    this.patients();
  },
  mounted() {
    this.getJurisdiction();
  },

  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem("getJurisdiction");
    },
    patients() {
      let obj = { name: this.patientnum, pagination: this.pagination };
      api
        .patient(obj)
        .then((res) => {
          this.data = [];
          this.pagination.total = res.count;
          res.data.forEach((data) => {
            console.log(data);
            if (data.hospital) {
              data.hospitalName = data.hospital.name;
            }
            data.is_diabetes == true
              ? (data.is_diabetes = "是")
              : (data.is_diabetes = "否");
            if (data.icu_date) {
              data.icu_date = moment(data.icu_date).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            if (data.out_icu_date) {
              data.out_icu_date = moment(data.out_icu_date).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            data.key = data.objectId;
            this.data.push(data);
            this.show = true;
          });
          this.loading = false;
        })
        .catch(() => { });
    },

    // async patientStatic2() {
    //   let obj = { name: this.patientnum };
    //   await api
    //     .patientStatic2(obj)
    //     .then((res) => {
    //       console.log(res);
    //       this.mergeData = [];
    //       for (const key in res.data) {
    //         console.log(res.data[key]);
    //         if (res.data[key].hospital) {
    //           res.data[key].hospitalName = res.data[key].hospital.name;
    //         }
    //         res.data[key].is_diabetes == true
    //           ? (res.data[key].is_diabetes = "是")
    //           : (res.data[key].is_diabetes = "否");
    //         if (res.data[key].icu_date) {
    //           res.data[key].icu_date = moment(res.data[key].icu_date).format(
    //             "YYYY-MM-DD HH:mm:ss"
    //           );
    //         }
    //         if (res.data[key].out_icu_date) {
    //           res.data[key].out_icu_date = moment(
    //             res.data[key].out_icu_date
    //           ).format("YYYY-MM-DD HH:mm:ss");
    //         }
    //         res.data[key].key = res.data[key].objectId;
    //         this.mergeData.push(res.data[key]);
    //       }
    //     })
    //     .catch(() => {});
    // },

    search() {
      if (this.patientnum) {
        this.pagination.current = 1;
      }
      this.patients();
      this.loading = true;
    },

    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.patients();
    },
    //导出
    async derive() {
      console.log('下载')
      this.loading = true;
      let obj = { name: this.patientnum };
      await api
        .patientDownload(obj)
        .then((res) => {
          console.log(res)
          if (res) {
            this.loading = false;
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = res.data.url;
            a.download = "患者信息数据记录";
            a.click();
          } else {
            this.loading = false;
            this.$message.warning("暂无数据");
          }
        })
      // await this.patientStatic2();
      // let mergeDatas = JSON.parse(JSON.stringify(this.mergeData));
      // console.log(mergeDatas);
      // mergeDatas.forEach((item) => {
      //   item.principalDiagnosis =
      //     item.icu_diagnosis.type +
      //     item.icu_diagnosis.department +
      //     item.icu_diagnosis.diagnosis_name;
      //   item.createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
      // });
      // if (mergeDatas.length > 0) {
      //   const initColumn = [
      //     {
      //       title: "病案号",
      //       dataIndex: "record_number",
      //       key: "record_number",
      //     },
      //     {
      //       title: "年龄",
      //       dataIndex: "age",
      //       key: "age",
      //     },
      //     {
      //       title: "性别",
      //       dataIndex: "sex",
      //       key: "sex",
      //     },
      //     {
      //       title: "体重",
      //       dataIndex: "weight",
      //       key: "weight",
      //     },
      //     {
      //       title: "是否有糖尿病",
      //       dataIndex: "is_diabetes",
      //       key: "is_diabetes",
      //     },
      //     {
      //       title: "ICU主要诊断",
      //       dataIndex: "principalDiagnosis",
      //       key: "principalDiagnosis",
      //     },
      //     { title: "入ICU时间", dataIndex: "icu_date", key: "icu_date" },
      //     {
      //       title: "出ICU时间",
      //       dataIndex: "out_icu_date",
      //       key: "out_icu_date",
      //     },
      //     {
      //       title: "出ICU时间来源",
      //       dataIndex: "out_icu_source",
      //       key: "out_icu_source",
      //     },
      //     {
      //       title: "转出ICU原由",
      //       dataIndex: "out_icu_reason",
      //       key: "out_icu_reason",
      //     },

      //     {
      //       title: "医院信息",
      //       dataIndex: "hospitalName",
      //       key: "hospitalName",
      //     },
      //     {
      //       title: "日期",
      //       dataIndex: "createdAt",
      //       key: "createdAt",
      //     },
      //   ];
      //   excelUtil.exportExcel(initColumn, mergeDatas, "患者信息数据记录.xlsx");
      // } else {
      //   this.$message.warning("暂无数据");
      // }
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.search-left {
  display: flex;
  align-items: center;
}
.search .ant-input {
  width: 210px;
  height: 32px;
  border-radius: 2px;
}
.search .ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}
/deep/ .ant-table-body {
  max-height: calc(100vh - 265px) !important;
}
</style>
